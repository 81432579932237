<template>
  <div class="flex flex-col grow gap-2" :class="{ 'overflow-hidden': workbook && items.length }">
    <ImporterHeader
      v-if="workbook"
      :importHandler="importItems"
      :mapHandler="processItems"
      :resetHandler="reset"
      :hasItems="!!items.length"
      :canMap="!!Object.keys(columnsMapToField).length"
      :loading="loading"
    />
    <template v-if="loading">
      <div class="flex flex-col w-full max-w-7xl self-center gap-4">
        <div class="text-lg font-medium text-surface-700">{{ loadingStage }}</div>
        <div v-if="processing" class="text-sm">{{ processingStage }}</div>
        <ProgressBar :value="loadingProgress" :showValue="false" style="height: 6px" />
        <div class="text-sm px-4">
          <p>
            This process may take a long time depending on how many vendors you are
            processing/importing. Please be patient and do not navigate away or close this tab until
            complete, even if the progress bar appears to have stalled.
          </p>
        </div>
      </div>
    </template>
    <template v-else-if="!items.length">
      <ImportUploader
        v-if="!workbook"
        :templateDownloader="downloadImportTemplate"
        :upload-handler="uploadFileHandler"
      />
      <ImportMapper
        v-else-if="workbook"
        :workbook="workbook"
        :supportedFields="supportedFields"
        :keySet="mappingKeySet"
        v-model:selectedSheet="selectedSheet"
        v-model:columnsMapToField="columnsMapToField"
        v-model:keyField="keyField"
      />
    </template>
    <!-- Apparently Vendor schema is still client OR this never ever worked -->
    <ImportTable v-else schema="client" :columns="visibleSupportedFields" v-model:items="items" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import ParseAddress from 'parse-address-string'
import Objects from '@/../imports/api/Objects'
import { useFields } from '@/components/importers/fields'
import { useGuesser } from '@/components/importers/guesser'
import { useImporter } from '@/components/importers/importer'
import { useImportSpreadsheet } from '@/components/importers/spreadsheet'
import ImportUploader from '@/components/importers/steps/ImportUploader.vue'
import ImportMapper from '@/components/importers/steps/ImportMapper.vue'
import ImporterHeader from '@/components/importers/ImporterHeader.vue'
import ImportTable from '@/components/importers/steps/ImportTable.vue'
import ProgressBar from 'primevue/progressbar'

const { buildDefaultObject } = Objects

const store = useStore()
const { bestGuess, guessByType } = useGuesser()
const { items, reset: resetImporter } = useImporter({
  perPage: 20
})
const { supportedFields, visibleSupportedFields } = useFields('vendor')

const {
  workbook,
  sheetData,
  selectedSheet,
  uploadFileHandler,
  downloadImportTemplate,
  reset: resetImportSpreadsheet,
  columnsMapToField
} = useImportSpreadsheet({
  schema: 'vendor'
})

const loadingStage = ref('')
const loading = computed(() => importing.value || processing.value)
const loadingProgress = computed(() => {
  if (amountToProcess.value) {
    return (processedItems.value / amountToProcess.value) * 100
  }

  return 0
})

const processing = ref(false)
const amountToProcess = ref(0)
const processedItems = ref(0)
const processingStage = ref('')
const importing = ref(false)

const columnsToIncludeAsAttribute = ref([])
const existingItems = ref({})
const keyField = ref('user_email')
const mappingKeySet = ref([
  {
    value: 'user_email',
    text: 'Email'
  }
])
const savedProvs = ref({})
const skipQuickbookDuplicates = ref(true)

function reset() {
  resetImportSpreadsheet()
  resetImporter()
  columnsToIncludeAsAttribute.value = []
  existingItems.value = {}
  savedProvs.value = {}
}

async function importItems() {
  importing.value = true
  processedItems.value = 0
  loadingStage.value = 'Beginning import'
  const chunkSize = 5
  const chunks = _.chunk(items.value, chunkSize)
  amountToProcess.value = chunks.length

  let saved = null
  try {
    saved = await c.waterfall(
      chunks.map((chunk, index) => async () => {
        const startPos = index * chunkSize
        loadingStage.value = `Updating clients (${startPos + 1} - ${startPos + chunk.length} of ${items.value.length})`
        await store.dispatch('Vendor/partialUpdate', {
          selected: chunk,
          alert: false
        })
        processedItems.value += 1
      })
    )

    await store.dispatch('alert', {
      message: `Imported/updated ${items.value.length} vendors!`
    })

    reset()
  } catch (e) {
    await store.dispatch('alert', {
      message: e.userMessage || 'There was an error, please contact support.',
      error: true
    })
    throw e
  } finally {
    importing.value = false
  }

  return saved
}

async function getExistingItems(keys) {
  if (!keys.length) return
  const chunkSize = 20
  const chunked = _.chunk(keys, chunkSize)

  // first get existing clients
  const sets = await c.waterfall(
    chunked.map((chunk, index) => async () => {
      const parts = chunk.join(',').split(/[\s\r\n,;]+/)
      const cleaned = c.cleanArray(parts)
      const joined = cleaned.join('||')
      if (!cleaned.length) return []
      const startPos = index * chunkSize
      processingStage.value = `Searching existing vendors (${startPos + 1} - ${startPos + chunk.length} of ${keys.length})...`
      return (
        await store.dispatch('Vendor/filter', {
          filters: {
            [keyField.value]: joined
          },
          limit: cleaned.length
        })
      ).set
    })
  )

  const items = sets.reduce((acc, set) => [...acc, ...set], [])

  existingItems.value = [...items]
}

function findExistingItemByImportedItem(importedItem) {
  const key = String(importedItem[keyField.value] || '')
    .toLowerCase()
    .trim()
  const keys = key.split(/[\s\r\n,;]+/)
  const found = existingItems.value.find((ei) =>
    keys.includes(String(ei[keyField.value]).toLowerCase().trim())
  )

  return found || {}
}

async function processItems() {
  processing.value = true

  const localSheetData = sheetData.value.filter((row) =>
    Object.keys(row).some((field) => /\w/i.test(String(row[field] || '')))
  )

  const userkeyindex = Object.values(columnsMapToField.value).indexOf(keyField.value)
  const userkeyfield = Object.keys(columnsMapToField.value)[userkeyindex]
  const keys = localSheetData.map((sd) => sd[userkeyfield])
  amountToProcess.value = keys.length
  loadingStage.value = 'Fetching any existing vendors...'
  await getExistingItems(keys)

  const processors = localSheetData.map((row) => async () => {
    const item = await processItem(row)
    processedItems.value += 1
    return item
  })

  // one at a time
  loadingStage.value = 'Matching up fetched vendors...'
  let localItems = await c.waterfall(processors)

  if (skipQuickbookDuplicates.value) {
    localItems = localItems.filter((item) => !item.skip)
  }

  // delete ones without names
  items.value = localItems.filter((item) => item.user_fname || item.user_lname || item.company_name)

  loadingStage.value = 'Reticulating splines...'
  processing.value = false
  amountToProcess.value = 0
  processedItems.value = 0

  return items.value
}

async function parseAddress(address) {
  let resolver = () => {}
  const promise = new Promise((r) => {
    resolver = r
  })
  ParseAddress(address, (err, addressParts) => {
    resolver(addressParts)
  })
  return promise
}

async function processItem(sheetItem) {
  // First map column values to actual fields
  const item = {}
  Object.keys(columnsMapToField.value).forEach((column) => {
    if (!columnsMapToField.value[column]) return // ignore

    item[columnsMapToField.value[column]] = sheetItem[column] || ''
  })

  loadingStage.value = `Processing vendor: ${item.company_name || item.user_fname + ' ' + item.user_lname}`
  const original = _.imm(item)

  if (
    skipQuickbookDuplicates.value &&
    ((original.user_name && original.user_name.includes(':')) ||
      (original.company_name && original.company_name.includes(':')))
  ) {
    original.skip = true
    return original
  }

  const existing = findExistingItemByImportedItem(item)

  const object = buildDefaultObject('vendor', {
    ...existing,
    // Only set non reactive text fields, everything else needs
    // to be specificially and individually set below
    ...Object.keys(item)
      .filter((field) => !/_has_|_is_|_net|_gross|_tax/.test(field))
      .reduce(
        (acc, field) => ({
          ...acc,
          [field]: item[field]
        }),
        {}
      ),

    // If there is no existing found, remove the id from the list
    ...(!Object.keys(existing).length ? { vendor_id: '' } : {})
  })

  const fullName = String(original.user_name || '').trim()
  let firstName = String(object.user_fname || '').trim()
  let lastName = String(object.user_lname || '').trim()
  if (fullName) {
    const splits = fullName.split(' ')
    if (splits.length > 1) {
      const last = splits.pop()
      firstName = firstName || splits.join(' ')
      lastName = lastName || last || ''
    } else {
      firstName = firstName || splits[0] || ''
    }
  }
  object.user_fname = firstName
  object.user_lname = lastName

  const companyName = String(object.company_name || '').trim()
  let fullAddress = String(original.full_address || '').trim()

  const defaultCity = store.state.session.user.user_city || ''
  const defaultProv =
    original.province_id || object.province_id || store.state.session.user.province_id || null
  const defaultCountry =
    original.country_id || object.country_id || store.state.session.user.country_id || null

  // Gotta search these up after if given names etc
  let prov = object.client_prov || object.province_name
  let country = object.client_country || object.country_name

  if (fullAddress) {
    if (firstName) {
      fullAddress = fullAddress.replace(new RegExp(`(${firstName}\\s*,?)`, 'ig'), '')
    }
    if (lastName) {
      fullAddress = fullAddress.replace(new RegExp(`(${lastName}\\s*,?)`, 'ig'), '')
    }
    if (companyName) {
      fullAddress = fullAddress.replace(new RegExp(`(${companyName}\\s*,?)`, 'ig'), '')
      object.company_name_short = companyName
    }
    const parsed = await parseAddress(fullAddress)
    const {
      street_address1: sa1,
      street_address2: sa2,
      city,
      state,
      postal_code: postal,
      country: parsedCountry
    } = parsed

    object.company_address = object.company_address || sa1 || ''
    object.company_suite = object.company_suite || sa2 || ''
    object.company_city = object.company_city || city || defaultCity || ''
    object.company_postal = object.company_postal || postal || ''
    prov = prov || state || ''
    country = country || parsedCountry || ''
  }

  // Search up country if provided
  if (country) {
    processingStage.value = 'Checking country...'
    object.country_id =
      (await bestGuess(object.country_id || country || defaultCountry, 'country')) || defaultCountry
  }

  // Search up state/prov if provided
  if (prov) {
    processingStage.value = 'Checking state/province...'
    if (prov in savedProvs.value) {
      object.province_id = object.province_id || savedProvs.value[prov].province_id
      object.province_name = object.province_name || savedProvs.value[prov].province_name
      object.province_abbr = object.province_abbr || savedProvs.value[prov].province_abbr
    } else {
      const { set: provs } = await store.dispatch('Province/search', {
        limit: 1,
        searchPhrase: prov,
        minScore: 1
      })
      if (provs.length) {
        object.province_id = object.province_id || provs[0].province_id
        object.province_name = object.province_name || provs[0].province_name
        object.province_abbr = object.province_abbr || provs[0].province_abbr
        savedProvs.value[prov] = provs[0]
      }
    }
  }

  if (!object.country_id) {
    object.country_id = object.country_id || defaultCountry
  }

  if (!object.province_id) {
    object.province_id = defaultProv
    savedProvs.value[prov] = defaultProv
  }

  if (original.user_email) {
    const splits = original.user_email.split(/[\s\r\n,;]+/)

    if (splits.length > 0) {
      let acctEmail = null
      if (companyName) {
        acctEmail =
          original.company_accounting_email ||
          splits.find((email) => /ap|payables|pay|acct|accounting|office|ar/i.test(email)) ||
          splits[splits.length - 1]
      }
      object.company_accounting_email = acctEmail

      object.user_email = splits[0]
    }
  }

  processingStage.value = 'Determining vendor owner...'
  object.company_owner =
    (await guessByType({
      phrase: object.company_owner || store.state.session.user.user_id,
      type: 'user',
      filters: {
        company_ids: `INSET${store.state.session.company.company_id}`
      }
    })) || store.state.session.user.user_id

  if (!object.company_email) {
    object.company_email = object.user_email || object.company_accounting_email || ''
  }

  if (!object.company_address) {
    object.company_address = object.user_address || ''
  }

  if (!object.company_suite) {
    object.company_suite = object.user_suite || ''
  }

  if (!object.company_postal) {
    object.company_postal = object.user_postal || ''
  }

  if (!object.company_city) {
    object.company_city = object.user_city || ''
  }

  if (!object.company_phone) {
    object.company_phone = object.user_phone || ''
  }

  if (!object.company_phone_ext) {
    object.company_phone_ext = object.user_phone_ext || ''
  }

  if (!object.company_phone_alt) {
    object.company_phone_alt = object.user_phone_alt || ''
  }

  if (!object.company_phone_alt_ext) {
    object.company_phone_alt_ext = object.user_phone_alt_ext || ''
  }

  return object
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
